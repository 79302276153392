// stylelint-disable declaration-no-important

html[dir="rtl"] {
  body {
    text-align: right;
    direction: rtl;
  }

  .hide-rtl {
    display: none !important;
  }

  .show-rtl {
    display: block !important;
  }

  .float-right {
    float: left !important;
  }

  .float-left {
    float: right !important;
  }
}

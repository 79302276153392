@keyframes progress-indeterminate {
  0% {
    left: -35%;
    right: 100%
  }

  100%, 60% {
    left: 100%;
    right: -90%
  }
}

.progress {
  appearance: none;
  background: rgba(22, 11, 10, 0.465);
  height: .75rem;
  line-height: .75rem;
  position: relative;
  width: 100%;
  border-radius: 0;

  &::-webkit-progress-bar {
    background: $min-black;
  }

  &::-webkit-progress-value {
    background-color: $primary
  }

  &::-moz-progress-bar {
    background-color: $primary
  }

  &::-ms-fill {
    background-color: $primary;
    border: none
  }
  .progress-bar {
    span {
      margin-left: 5px;
    }
  }
}

.progress-sm {
  height: .25rem;
}

.progress-bar-indeterminate {
  &:after,
  &:before {
    content: '';
    position: absolute;
    background-color: inherit;
    left: 0;
    will-change: left, right;
    top: 0;
    bottom: 0;
  }

  &:before {
    animation: progress-indeterminate 2.1s cubic-bezier(.65, .815, .735, .395) infinite;
  }
}

.favourite-placeholder {
  .card {
    .progress {
      height: 1.2rem;
      .progress-bar {
        &.danger {
          background-color: #fa4654;
        }
      }
    }
  }
}

.pagination {
  user-select: none;
}

.page-link {
  min-width: px2rem(26px);
  border-radius: $border-radius;

  &:hover {
    background: transparent;
  }
}

.page-item {
  text-align: center;

  &.page-prev,
  &.page-next {
    flex: 0 0 50%;
    text-align: left;
  }

  &.page-next {
    margin-left: auto;
    text-align: right;
  }
}

.page-item-subtitle {
  margin-bottom: 2px;
  font-size: 12px;
  color: $text-muted;
  text-transform: uppercase;

  .page-item.disabled &{
    color: $pagination-disabled-color;
  }
}

.page-item-title {
  font-size: $h3-font-size;
  font-weight: 400;
  color: $text-color;

  .page-link:hover & {
    color: $link-color;
  }

  .page-item.disabled &{
    color: $pagination-disabled-color;
  }
}

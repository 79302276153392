#image_preview {
  max-width: 300px;
}

textarea {
  &[cols] {
    height: auto;
  }
}

.form-label {
  display: block;
  margin-bottom: .375rem;
  font-size: $h5-font-size;
  font-weight: 600;
}

.mb-2 {
  display: block;

  &.row {
    display: flex;
  }
}
.input-group {
  a.btn {
    i.icon {
      &.fe-calendar {
        vertical-align: -0.25em;
        color: #fff;
      }
    }
  }
}
.form-control {
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }

  &:disabled,
  &.disabled {
    color: $text-muted;
    user-select: none;
  }
}

.form-control-light {
  background-color: $min-black;
  border-color: $min-black;
}

.form-footer {
  margin-top: 2rem;
}

.form-fieldset {
  padding: 1rem;
  margin-bottom: 1rem;
  background: $min-black;
  border: 1px solid $border-color;
  border-radius: $border-radius;
}

.form-required {
  color: $red;
}

.custom-control-label::before {
  background: transparent;
  border: 1px solid $border-color;
}

/**
Form help
 */
.form-help {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  text-align: center;
  line-height: 1.00rem;
  color: $text-muted;
  background: #dedede;
  border-radius: 50%;
  font-size: px2rem(12px);
  transition: .3s background-color, .3s color;
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  &:hover,
  &[aria-describedby] {
    background: $primary;
    color: #fff;
  }
}

.example {
  padding: 1.5rem;
  margin: 1rem 0 0;
  border: 1px solid $border-color;
  border-radius: 3px 3px 0 0;
}

.example-bg {
  background: $min-black;
}

.example + .highlight {
  margin-top: 0;
  border-top: none;
  border-radius: 0 0 3px 3px;
}

.example-column {
  margin: 0 auto;

  > .card:last-of-type {
    margin-bottom: 0;
  }
}

.example-column-1 {
  max-width: 20rem;
}

.example-column-2 {
  max-width: 40rem;
}

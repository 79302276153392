.modal-backdrop {
  backdrop-filter: blur(2px);
  background: $light-black;
}

.modal-content {
  border-radius: .125rem;
  box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
  -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
  -moz-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
  border: 0;
}

#loginModal.modal, #updateModal.modal {
	top: 25%;
}

#loadingModal {
	top: 35%;
	.modal-footer {
		border-top: none;
	}
}

.modal-dialog {
	.modal-content {
		box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
		-webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
		-moz-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
		border: 0;
		border-radius: .125rem;
		.md-form {
			margin-top: 1.5rem;
			position: relative;
			&.validate {
				margin-bottom: 2.5rem;
			}
			p.errorPlaceholder {
				color: red;
			}
			i {
				&.icon {
					&.prefix {
					  display: inline-block;
					  position: absolute;
					  transition: color .2s;
					  top: .25rem;
					  font-size: 1.75rem;
					  font-weight: 700;
					  color: #9e9e9e!important;
					}
				}
			}
			.prefix~input {
				margin-left: 2.5rem;
				width: calc(100% - 2.5rem);
			}
			.prefix~label {
				margin-left: 2.5rem;
			}
			.form-control {
				margin: 0 0 .5rem 0;
				border-radius: 0;
				padding: .6rem 0 .4rem 0;
				background-color: transparent;
				height: auto;
			}
			input[type=date], input[type=datetime-local], input[type=email], input[type=number], input[type=password], input[type=search-md], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], textarea.md-textarea {
				transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
				outline: 0;
				box-shadow: none;
				border: none;
				border-bottom: 1px solid #ced4da;
				border-radius: 0;
				box-sizing: content-box;
				background-color: transparent;
			}

			input[type=date]:focus:not([readonly]), input[type=datetime-local]:focus:not([readonly]), input[type=email]:focus:not([readonly]), input[type=number]:focus:not([readonly]), input[type=password]:focus:not([readonly]), input[type=search-md]:focus:not([readonly]), input[type=search]:focus:not([readonly]), input[type=tel]:focus:not([readonly]), input[type=text]:focus:not([readonly]), input[type=time]:focus:not([readonly]), input[type=url]:focus:not([readonly]), textarea.md-textarea:focus:not([readonly]) {
				box-shadow: 0 1px 0 0 #4285f4;
				border-bottom: 1px solid #4285f4;
			}
			input[type=date]:focus:not([readonly])+label, input[type=datetime-local]:focus:not([readonly])+label, input[type=email]:focus:not([readonly])+label, input[type=number]:focus:not([readonly])+label, input[type=password]:focus:not([readonly])+label, input[type=search-md]:focus:not([readonly])+label, input[type=search]:focus:not([readonly])+label, input[type=tel]:focus:not([readonly])+label, input[type=text]:focus:not([readonly])+label, input[type=time]:focus:not([readonly])+label, input[type=url]:focus:not([readonly])+label, textarea.md-textarea:focus:not([readonly])+label {
				color: #4285f4;
			}

			input[type=date], input[type=datetime-local], input[type=email], input[type=number], input[type=password], input[type=search-md], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], textarea.md-textarea,
			input[type=date]:focus:not([readonly]), input[type=datetime-local]:focus:not([readonly]), input[type=email]:focus:not([readonly]), input[type=number]:focus:not([readonly]), input[type=password]:focus:not([readonly]), input[type=search-md]:focus:not([readonly]), input[type=search]:focus:not([readonly]), input[type=tel]:focus:not([readonly]), input[type=text]:focus:not([readonly]), input[type=time]:focus:not([readonly]), input[type=url]:focus:not([readonly]), textarea.md-textarea:focus:not([readonly]) {
				&.error {
					color: red;
					border-bottom: 1px solid red;
					box-shadow: 0 1px 0 0 red;
				}
			}

			label {
				position: absolute;
			    top: -5px;
			    left: 0;
			    font-size: 1rem;
			    transition: transform .2s ease-out,color .2s ease-out;
			    transform-origin: 0 100%;
			    transform: translateY(12px);
			    cursor: text;
			    color: #757575;
			    display: inline-block;
			    margin-bottom: .5rem;
			    &.active {
			    	transform: translateY(-14px) scale(.8);
			    }
			}
		}
		.modal-footer {
			.btn {
		        box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
		        padding: .84rem 2.14rem;
		        font-size: .81rem;
		        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
		        margin: .375rem;
		        border: 0;
		        border-radius: .125rem;
		        cursor: pointer;
		        text-transform: uppercase;
		        white-space: normal;
		        word-wrap: break-word;
			}
		}
	}
}


// stylelint-disable declaration-no-important

/**
COLORS VARIATIONS
 */
@if $generate-color-hues {
  @each $color, $value in $colors {
    .bg-#{$color}-lightest { background: mix($value, #fff, 10%); }
    .bg-#{$color}-lighter { background: mix($value, #fff, 30%); }
    .bg-#{$color}-light { background: mix($value, #fff, 70%); }
    .bg-#{$color}-dark { background: mix($value, #000, 80%); }
    .bg-#{$color}-darker { background: mix($value, #000, 40%); }
    .bg-#{$color}-darkest { background: mix($value, #000, 20%); }

    .bg-#{$color}-lt {
      color: $value !important;
      background: theme-color-lighter($value) !important;
    }
  }
}

/**
SOCIAL COLORS
 */
@if $generate-social-colors {
  @each $color, $value in $social-colors {
    .bg-#{$color} {
      color: #fff !important;
      background: $value !important;
    }

    .text-#{$color} {
      color: $value !important;
    }
  }
}


@if $generate-colors {
  @each $color, $value in $colors {
    .bg-#{$color} {
      background: $value;
    }

    .text-#{$color} {
      color: $value !important;
    }

    .bg-#{$color}-lt {
      color: $value !important;
      background: theme-color-lighter($value) !important;
    }
  }
}

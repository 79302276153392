@font-face {
    font-family: 'tabler-webfont';
    src: url("../dist/fonts/tabler-webfont/tabler-webfont.eot?d88ab45f67181eccbf3579d96bdf650a?#iefix") format("embedded-opentype"),
url("../fonts/tabler-webfont/tabler-webfont.woff2?d88ab45f67181eccbf3579d96bdf650a") format("woff2"),
url("../fonts/tabler-webfont/tabler-webfont.woff?d88ab45f67181eccbf3579d96bdf650a") format("woff"),
url("../fonts/tabler-webfont/tabler-webfont.ttf?d88ab45f67181eccbf3579d96bdf650a") format("truetype"),
url("../fonts/tabler-webfont/tabler-webfont.svg?d88ab45f67181eccbf3579d96bdf650a#tabler-webfont") format("svg");
    font-weight: normal;
    font-style: normal;
}

.icon {
    font-family: 'tabler-webfont' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    .icon {
        vertical-align: -0.15em;
    }
}

$icon-brand-android: '\f101';
.brand-android:before {
    content: $icon-brand-android;
}

$icon-brand-apple: '\f102';
.brand-apple:before {
    content: $icon-brand-apple;
}

$icon-brand-asana: '\f103';
.brand-asana:before {
    content: $icon-brand-asana;
}

$icon-brand-atlassian: '\f104';
.brand-atlassian:before {
    content: $icon-brand-atlassian;
}

$icon-brand-basecamp: '\f105';
.brand-basecamp:before {
    content: $icon-brand-basecamp;
}

$icon-brand-behance: '\f106';
.brand-behance:before {
    content: $icon-brand-behance;
}

$icon-brand-bing: '\f107';
.brand-bing:before {
    content: $icon-brand-bing;
}

$icon-brand-bitbucket: '\f108';
.brand-bitbucket:before {
    content: $icon-brand-bitbucket;
}

$icon-brand-bitcoin: '\f109';
.brand-bitcoin:before {
    content: $icon-brand-bitcoin;
}

$icon-brand-blogger: '\f10a';
.brand-blogger:before {
    content: $icon-brand-blogger;
}

$icon-brand-bootstrap: '\f10b';
.brand-bootstrap:before {
    content: $icon-brand-bootstrap;
}

$icon-brand-buddy: '\f10c';
.brand-buddy:before {
    content: $icon-brand-buddy;
}

$icon-brand-circleci: '\f10d';
.brand-circleci:before {
    content: $icon-brand-circleci;
}

$icon-brand-codepen: '\f10e';
.brand-codepen:before {
    content: $icon-brand-codepen;
}

$icon-brand-codeship: '\f10f';
.brand-codeship:before {
    content: $icon-brand-codeship;
}

$icon-brand-css3: '\f110';
.brand-css3:before {
    content: $icon-brand-css3;
}

$icon-brand-digitalocean: '\f111';
.brand-digitalocean:before {
    content: $icon-brand-digitalocean;
}

$icon-brand-disqus: '\f112';
.brand-disqus:before {
    content: $icon-brand-disqus;
}

$icon-brand-docker: '\f113';
.brand-docker:before {
    content: $icon-brand-docker;
}

$icon-brand-dribbble: '\f114';
.brand-dribbble:before {
    content: $icon-brand-dribbble;
}

$icon-brand-dropbox: '\f115';
.brand-dropbox:before {
    content: $icon-brand-dropbox;
}

$icon-brand-facebook: '\f116';
.brand-facebook:before {
    content: $icon-brand-facebook;
}

$icon-brand-figma: '\f117';
.brand-figma:before {
    content: $icon-brand-figma;
}

$icon-brand-flickr: '\f118';
.brand-flickr:before {
    content: $icon-brand-flickr;
}

$icon-brand-foursquare: '\f119';
.brand-foursquare:before {
    content: $icon-brand-foursquare;
}

$icon-brand-git: '\f11a';
.brand-git:before {
    content: $icon-brand-git;
}

$icon-brand-github: '\f11b';
.brand-github:before {
    content: $icon-brand-github;
}

$icon-brand-gitlab: '\f11c';
.brand-gitlab:before {
    content: $icon-brand-gitlab;
}

$icon-brand-google: '\f11d';
.brand-google:before {
    content: $icon-brand-google;
}

$icon-brand-googleplus: '\f11e';
.brand-googleplus:before {
    content: $icon-brand-googleplus;
}

$icon-brand-gravatar: '\f11f';
.brand-gravatar:before {
    content: $icon-brand-gravatar;
}

$icon-brand-hipchat: '\f120';
.brand-hipchat:before {
    content: $icon-brand-hipchat;
}

$icon-brand-html5: '\f121';
.brand-html5:before {
    content: $icon-brand-html5;
}

$icon-brand-instagram: '\f122';
.brand-instagram:before {
    content: $icon-brand-instagram;
}

$icon-brand-invision: '\f123';
.brand-invision:before {
    content: $icon-brand-invision;
}

$icon-brand-javascript: '\f124';
.brand-javascript:before {
    content: $icon-brand-javascript;
}

$icon-brand-kickstarter: '\f125';
.brand-kickstarter:before {
    content: $icon-brand-kickstarter;
}

$icon-brand-linkedin: '\f126';
.brand-linkedin:before {
    content: $icon-brand-linkedin;
}

$icon-brand-medium: '\f127';
.brand-medium:before {
    content: $icon-brand-medium;
}

$icon-brand-meetup: '\f128';
.brand-meetup:before {
    content: $icon-brand-meetup;
}

$icon-brand-messenger: '\f129';
.brand-messenger:before {
    content: $icon-brand-messenger;
}

$icon-brand-netflix: '\f12a';
.brand-netflix:before {
    content: $icon-brand-netflix;
}

$icon-brand-pagekit: '\f12b';
.brand-pagekit:before {
    content: $icon-brand-pagekit;
}

$icon-brand-paypal: '\f12c';
.brand-paypal:before {
    content: $icon-brand-paypal;
}

$icon-brand-pinterest: '\f12d';
.brand-pinterest:before {
    content: $icon-brand-pinterest;
}

$icon-brand-producthunt: '\f12e';
.brand-producthunt:before {
    content: $icon-brand-producthunt;
}

$icon-brand-rss: '\f12f';
.brand-rss:before {
    content: $icon-brand-rss;
}

$icon-brand-sass: '\f130';
.brand-sass:before {
    content: $icon-brand-sass;
}

$icon-brand-sentry: '\f131';
.brand-sentry:before {
    content: $icon-brand-sentry;
}

$icon-brand-skype: '\f132';
.brand-skype:before {
    content: $icon-brand-skype;
}

$icon-brand-slack: '\f133';
.brand-slack:before {
    content: $icon-brand-slack;
}

$icon-brand-snapchat: '\f134';
.brand-snapchat:before {
    content: $icon-brand-snapchat;
}

$icon-brand-spotify: '\f135';
.brand-spotify:before {
    content: $icon-brand-spotify;
}

$icon-brand-strava: '\f136';
.brand-strava:before {
    content: $icon-brand-strava;
}

$icon-brand-stripe: '\f137';
.brand-stripe:before {
    content: $icon-brand-stripe;
}

$icon-brand-tabler: '\f138';
.brand-tabler:before {
    content: $icon-brand-tabler;
}

$icon-brand-telegram: '\f139';
.brand-telegram:before {
    content: $icon-brand-telegram;
}

$icon-brand-tumblr: '\f13a';
.brand-tumblr:before {
    content: $icon-brand-tumblr;
}

$icon-brand-twitter: '\f13b';
.brand-twitter:before {
    content: $icon-brand-twitter;
}

$icon-brand-typescript: '\f13c';
.brand-typescript:before {
    content: $icon-brand-typescript;
}

$icon-brand-uber: '\f13d';
.brand-uber:before {
    content: $icon-brand-uber;
}

$icon-brand-uikit: '\f13e';
.brand-uikit:before {
    content: $icon-brand-uikit;
}

$icon-brand-vimeo: '\f13f';
.brand-vimeo:before {
    content: $icon-brand-vimeo;
}

$icon-brand-vk: '\f140';
.brand-vk:before {
    content: $icon-brand-vk;
}

$icon-brand-wechat: '\f141';
.brand-wechat:before {
    content: $icon-brand-wechat;
}

$icon-brand-whatsapp: '\f142';
.brand-whatsapp:before {
    content: $icon-brand-whatsapp;
}

$icon-brand-wordpress: '\f143';
.brand-wordpress:before {
    content: $icon-brand-wordpress;
}

$icon-brand-ycombinator: '\f144';
.brand-ycombinator:before {
    content: $icon-brand-ycombinator;
}

$icon-brand-youtube: '\f145';
.brand-youtube:before {
    content: $icon-brand-youtube;
}

$icon-fe-activity: '\f146';
.fe-activity:before {
    content: $icon-fe-activity;
}

$icon-fe-airplay: '\f147';
.fe-airplay:before {
    content: $icon-fe-airplay;
}

$icon-fe-alert-circle: '\f148';
.fe-alert-circle:before {
    content: $icon-fe-alert-circle;
}

$icon-fe-alert-octagon: '\f149';
.fe-alert-octagon:before {
    content: $icon-fe-alert-octagon;
}

$icon-fe-alert-triangle: '\f14a';
.fe-alert-triangle:before {
    content: $icon-fe-alert-triangle;
}

$icon-fe-align-center: '\f14b';
.fe-align-center:before {
    content: $icon-fe-align-center;
}

$icon-fe-align-justify: '\f14c';
.fe-align-justify:before {
    content: $icon-fe-align-justify;
}

$icon-fe-align-left: '\f14d';
.fe-align-left:before {
    content: $icon-fe-align-left;
}

$icon-fe-align-right: '\f14e';
.fe-align-right:before {
    content: $icon-fe-align-right;
}

$icon-fe-anchor: '\f14f';
.fe-anchor:before {
    content: $icon-fe-anchor;
}

$icon-fe-aperture: '\f150';
.fe-aperture:before {
    content: $icon-fe-aperture;
}

$icon-fe-archive: '\f151';
.fe-archive:before {
    content: $icon-fe-archive;
}

$icon-fe-arrow-down-circle: '\f152';
.fe-arrow-down-circle:before {
    content: $icon-fe-arrow-down-circle;
}

$icon-fe-arrow-down-left: '\f153';
.fe-arrow-down-left:before {
    content: $icon-fe-arrow-down-left;
}

$icon-fe-arrow-down-right: '\f154';
.fe-arrow-down-right:before {
    content: $icon-fe-arrow-down-right;
}

$icon-fe-arrow-down: '\f155';
.fe-arrow-down:before {
    content: $icon-fe-arrow-down;
}

$icon-fe-arrow-left-circle: '\f156';
.fe-arrow-left-circle:before {
    content: $icon-fe-arrow-left-circle;
}

$icon-fe-arrow-left: '\f157';
.fe-arrow-left:before {
    content: $icon-fe-arrow-left;
}

$icon-fe-arrow-right-circle: '\f158';
.fe-arrow-right-circle:before {
    content: $icon-fe-arrow-right-circle;
}

$icon-fe-arrow-right: '\f159';
.fe-arrow-right:before {
    content: $icon-fe-arrow-right;
}

$icon-fe-arrow-up-circle: '\f15a';
.fe-arrow-up-circle:before {
    content: $icon-fe-arrow-up-circle;
}

$icon-fe-arrow-up-left: '\f15b';
.fe-arrow-up-left:before {
    content: $icon-fe-arrow-up-left;
}

$icon-fe-arrow-up-right: '\f15c';
.fe-arrow-up-right:before {
    content: $icon-fe-arrow-up-right;
}

$icon-fe-arrow-up: '\f15d';
.fe-arrow-up:before {
    content: $icon-fe-arrow-up;
}

$icon-fe-at-sign: '\f15e';
.fe-at-sign:before {
    content: $icon-fe-at-sign;
}

$icon-fe-award: '\f15f';
.fe-award:before {
    content: $icon-fe-award;
}

$icon-fe-bar-chart-2: '\f160';
.fe-bar-chart-2:before {
    content: $icon-fe-bar-chart-2;
}

$icon-fe-bar-chart: '\f161';
.fe-bar-chart:before {
    content: $icon-fe-bar-chart;
}

$icon-fe-battery-charging: '\f162';
.fe-battery-charging:before {
    content: $icon-fe-battery-charging;
}

$icon-fe-battery: '\f163';
.fe-battery:before {
    content: $icon-fe-battery;
}

$icon-fe-bell-off: '\f164';
.fe-bell-off:before {
    content: $icon-fe-bell-off;
}

$icon-fe-bell: '\f165';
.fe-bell:before {
    content: $icon-fe-bell;
}

$icon-fe-bluetooth: '\f166';
.fe-bluetooth:before {
    content: $icon-fe-bluetooth;
}

$icon-fe-bold: '\f167';
.fe-bold:before {
    content: $icon-fe-bold;
}

$icon-fe-book-open: '\f168';
.fe-book-open:before {
    content: $icon-fe-book-open;
}

$icon-fe-book: '\f169';
.fe-book:before {
    content: $icon-fe-book;
}

$icon-fe-bookmark: '\f16a';
.fe-bookmark:before {
    content: $icon-fe-bookmark;
}

$icon-fe-box: '\f16b';
.fe-box:before {
    content: $icon-fe-box;
}

$icon-fe-briefcase: '\f16c';
.fe-briefcase:before {
    content: $icon-fe-briefcase;
}

$icon-fe-calendar: '\f16d';
.fe-calendar:before {
    content: $icon-fe-calendar;
}

$icon-fe-camera-off: '\f16e';
.fe-camera-off:before {
    content: $icon-fe-camera-off;
}

$icon-fe-camera: '\f16f';
.fe-camera:before {
    content: $icon-fe-camera;
}

$icon-fe-cast: '\f170';
.fe-cast:before {
    content: $icon-fe-cast;
}

$icon-fe-check-circle: '\f171';
.fe-check-circle:before {
    content: $icon-fe-check-circle;
}

$icon-fe-check-square: '\f172';
.fe-check-square:before {
    content: $icon-fe-check-square;
}

$icon-fe-check: '\f173';
.fe-check:before {
    content: $icon-fe-check;
}

$icon-fe-chevron-down: '\f174';
.fe-chevron-down:before {
    content: $icon-fe-chevron-down;
}

$icon-fe-chevron-left: '\f175';
.fe-chevron-left:before {
    content: $icon-fe-chevron-left;
}

$icon-fe-chevron-right: '\f176';
.fe-chevron-right:before {
    content: $icon-fe-chevron-right;
}

$icon-fe-chevron-up: '\f177';
.fe-chevron-up:before {
    content: $icon-fe-chevron-up;
}

$icon-fe-chevrons-down: '\f178';
.fe-chevrons-down:before {
    content: $icon-fe-chevrons-down;
}

$icon-fe-chevrons-left: '\f179';
.fe-chevrons-left:before {
    content: $icon-fe-chevrons-left;
}

$icon-fe-chevrons-right: '\f17a';
.fe-chevrons-right:before {
    content: $icon-fe-chevrons-right;
}

$icon-fe-chevrons-up: '\f17b';
.fe-chevrons-up:before {
    content: $icon-fe-chevrons-up;
}

$icon-fe-chrome: '\f17c';
.fe-chrome:before {
    content: $icon-fe-chrome;
}

$icon-fe-circle: '\f17d';
.fe-circle:before {
    content: $icon-fe-circle;
}

$icon-fe-clipboard: '\f17e';
.fe-clipboard:before {
    content: $icon-fe-clipboard;
}

$icon-fe-clock: '\f17f';
.fe-clock:before {
    content: $icon-fe-clock;
}

$icon-fe-cloud-drizzle: '\f180';
.fe-cloud-drizzle:before {
    content: $icon-fe-cloud-drizzle;
}

$icon-fe-cloud-lightning: '\f181';
.fe-cloud-lightning:before {
    content: $icon-fe-cloud-lightning;
}

$icon-fe-cloud-off: '\f182';
.fe-cloud-off:before {
    content: $icon-fe-cloud-off;
}

$icon-fe-cloud-rain: '\f183';
.fe-cloud-rain:before {
    content: $icon-fe-cloud-rain;
}

$icon-fe-cloud-snow: '\f184';
.fe-cloud-snow:before {
    content: $icon-fe-cloud-snow;
}

$icon-fe-cloud: '\f185';
.fe-cloud:before {
    content: $icon-fe-cloud;
}

$icon-fe-code: '\f186';
.fe-code:before {
    content: $icon-fe-code;
}

$icon-fe-codepen: '\f187';
.fe-codepen:before {
    content: $icon-fe-codepen;
}

$icon-fe-codesandbox: '\f188';
.fe-codesandbox:before {
    content: $icon-fe-codesandbox;
}

$icon-fe-coffee: '\f189';
.fe-coffee:before {
    content: $icon-fe-coffee;
}

$icon-fe-columns: '\f18a';
.fe-columns:before {
    content: $icon-fe-columns;
}

$icon-fe-command: '\f18b';
.fe-command:before {
    content: $icon-fe-command;
}

$icon-fe-compass: '\f18c';
.fe-compass:before {
    content: $icon-fe-compass;
}

$icon-fe-copy: '\f18d';
.fe-copy:before {
    content: $icon-fe-copy;
}

$icon-fe-corner-down-left: '\f18e';
.fe-corner-down-left:before {
    content: $icon-fe-corner-down-left;
}

$icon-fe-corner-down-right: '\f18f';
.fe-corner-down-right:before {
    content: $icon-fe-corner-down-right;
}

$icon-fe-corner-left-down: '\f190';
.fe-corner-left-down:before {
    content: $icon-fe-corner-left-down;
}

$icon-fe-corner-left-up: '\f191';
.fe-corner-left-up:before {
    content: $icon-fe-corner-left-up;
}

$icon-fe-corner-right-down: '\f192';
.fe-corner-right-down:before {
    content: $icon-fe-corner-right-down;
}

$icon-fe-corner-right-up: '\f193';
.fe-corner-right-up:before {
    content: $icon-fe-corner-right-up;
}

$icon-fe-corner-up-left: '\f194';
.fe-corner-up-left:before {
    content: $icon-fe-corner-up-left;
}

$icon-fe-corner-up-right: '\f195';
.fe-corner-up-right:before {
    content: $icon-fe-corner-up-right;
}

$icon-fe-cpu: '\f196';
.fe-cpu:before {
    content: $icon-fe-cpu;
}

$icon-fe-credit-card: '\f197';
.fe-credit-card:before {
    content: $icon-fe-credit-card;
}

$icon-fe-crop: '\f198';
.fe-crop:before {
    content: $icon-fe-crop;
}

$icon-fe-crosshair: '\f199';
.fe-crosshair:before {
    content: $icon-fe-crosshair;
}

$icon-fe-database: '\f19a';
.fe-database:before {
    content: $icon-fe-database;
}

$icon-fe-delete: '\f19b';
.fe-delete:before {
    content: $icon-fe-delete;
}

$icon-fe-disc: '\f19c';
.fe-disc:before {
    content: $icon-fe-disc;
}

$icon-fe-dollar-sign: '\f19d';
.fe-dollar-sign:before {
    content: $icon-fe-dollar-sign;
}

$icon-fe-download-cloud: '\f19e';
.fe-download-cloud:before {
    content: $icon-fe-download-cloud;
}

$icon-fe-download: '\f19f';
.fe-download:before {
    content: $icon-fe-download;
}

$icon-fe-droplet: '\f1a0';
.fe-droplet:before {
    content: $icon-fe-droplet;
}

$icon-fe-edit-2: '\f1a1';
.fe-edit-2:before {
    content: $icon-fe-edit-2;
}

$icon-fe-edit-3: '\f1a2';
.fe-edit-3:before {
    content: $icon-fe-edit-3;
}

$icon-fe-edit: '\f1a3';
.fe-edit:before {
    content: $icon-fe-edit;
}

$icon-fe-external-link: '\f1a4';
.fe-external-link:before {
    content: $icon-fe-external-link;
}

$icon-fe-eye-off: '\f1a5';
.fe-eye-off:before {
    content: $icon-fe-eye-off;
}

$icon-fe-eye: '\f1a6';
.fe-eye:before {
    content: $icon-fe-eye;
}

$icon-fe-facebook: '\f1a7';
.fe-facebook:before {
    content: $icon-fe-facebook;
}

$icon-fe-fast-forward: '\f1a8';
.fe-fast-forward:before {
    content: $icon-fe-fast-forward;
}

$icon-fe-feather: '\f1a9';
.fe-feather:before {
    content: $icon-fe-feather;
}

$icon-fe-figma: '\f1aa';
.fe-figma:before {
    content: $icon-fe-figma;
}

$icon-fe-file-minus: '\f1ab';
.fe-file-minus:before {
    content: $icon-fe-file-minus;
}

$icon-fe-file-plus: '\f1ac';
.fe-file-plus:before {
    content: $icon-fe-file-plus;
}

$icon-fe-file-text: '\f1ad';
.fe-file-text:before {
    content: $icon-fe-file-text;
}

$icon-fe-file: '\f1ae';
.fe-file:before {
    content: $icon-fe-file;
}

$icon-fe-film: '\f1af';
.fe-film:before {
    content: $icon-fe-film;
}

$icon-fe-filter: '\f1b0';
.fe-filter:before {
    content: $icon-fe-filter;
}

$icon-fe-flag: '\f1b1';
.fe-flag:before {
    content: $icon-fe-flag;
}

$icon-fe-folder-minus: '\f1b2';
.fe-folder-minus:before {
    content: $icon-fe-folder-minus;
}

$icon-fe-folder-plus: '\f1b3';
.fe-folder-plus:before {
    content: $icon-fe-folder-plus;
}

$icon-fe-folder: '\f1b4';
.fe-folder:before {
    content: $icon-fe-folder;
}

$icon-fe-frown: '\f1b5';
.fe-frown:before {
    content: $icon-fe-frown;
}

$icon-fe-gift: '\f1b6';
.fe-gift:before {
    content: $icon-fe-gift;
}

$icon-fe-git-branch: '\f1b7';
.fe-git-branch:before {
    content: $icon-fe-git-branch;
}

$icon-fe-git-commit: '\f1b8';
.fe-git-commit:before {
    content: $icon-fe-git-commit;
}

$icon-fe-git-merge: '\f1b9';
.fe-git-merge:before {
    content: $icon-fe-git-merge;
}

$icon-fe-git-pull-request: '\f1ba';
.fe-git-pull-request:before {
    content: $icon-fe-git-pull-request;
}

$icon-fe-github: '\f1bb';
.fe-github:before {
    content: $icon-fe-github;
}

$icon-fe-gitlab: '\f1bc';
.fe-gitlab:before {
    content: $icon-fe-gitlab;
}

$icon-fe-globe: '\f1bd';
.fe-globe:before {
    content: $icon-fe-globe;
}

$icon-fe-grid: '\f1be';
.fe-grid:before {
    content: $icon-fe-grid;
}

$icon-fe-hard-drive: '\f1bf';
.fe-hard-drive:before {
    content: $icon-fe-hard-drive;
}

$icon-fe-hash: '\f1c0';
.fe-hash:before {
    content: $icon-fe-hash;
}

$icon-fe-headphones: '\f1c1';
.fe-headphones:before {
    content: $icon-fe-headphones;
}

$icon-fe-heart: '\f1c2';
.fe-heart:before {
    content: $icon-fe-heart;
}

$icon-fe-help-circle: '\f1c3';
.fe-help-circle:before {
    content: $icon-fe-help-circle;
}

$icon-fe-hexagon: '\f1c4';
.fe-hexagon:before {
    content: $icon-fe-hexagon;
}

$icon-fe-home: '\f1c5';
.fe-home:before {
    content: $icon-fe-home;
}

$icon-fe-image: '\f1c6';
.fe-image:before {
    content: $icon-fe-image;
}

$icon-fe-inbox: '\f1c7';
.fe-inbox:before {
    content: $icon-fe-inbox;
}

$icon-fe-info: '\f1c8';
.fe-info:before {
    content: $icon-fe-info;
}

$icon-fe-instagram: '\f1c9';
.fe-instagram:before {
    content: $icon-fe-instagram;
}

$icon-fe-italic: '\f1ca';
.fe-italic:before {
    content: $icon-fe-italic;
}

$icon-fe-key: '\f1cb';
.fe-key:before {
    content: $icon-fe-key;
}

$icon-fe-layers: '\f1cc';
.fe-layers:before {
    content: $icon-fe-layers;
}

$icon-fe-layout: '\f1cd';
.fe-layout:before {
    content: $icon-fe-layout;
}

$icon-fe-life-buoy: '\f1ce';
.fe-life-buoy:before {
    content: $icon-fe-life-buoy;
}

$icon-fe-link-2: '\f1cf';
.fe-link-2:before {
    content: $icon-fe-link-2;
}

$icon-fe-link: '\f1d0';
.fe-link:before {
    content: $icon-fe-link;
}

$icon-fe-linkedin: '\f1d1';
.fe-linkedin:before {
    content: $icon-fe-linkedin;
}

$icon-fe-list: '\f1d2';
.fe-list:before {
    content: $icon-fe-list;
}

$icon-fe-loader: '\f1d3';
.fe-loader:before {
    content: $icon-fe-loader;
}

$icon-fe-lock: '\f1d4';
.fe-lock:before {
    content: $icon-fe-lock;
}

$icon-fe-log-in: '\f1d5';
.fe-log-in:before {
    content: $icon-fe-log-in;
}

$icon-fe-log-out: '\f1d6';
.fe-log-out:before {
    content: $icon-fe-log-out;
}

$icon-fe-mail: '\f1d7';
.fe-mail:before {
    content: $icon-fe-mail;
}

$icon-fe-map-pin: '\f1d8';
.fe-map-pin:before {
    content: $icon-fe-map-pin;
}

$icon-fe-map: '\f1d9';
.fe-map:before {
    content: $icon-fe-map;
}

$icon-fe-maximize-2: '\f1da';
.fe-maximize-2:before {
    content: $icon-fe-maximize-2;
}

$icon-fe-maximize: '\f1db';
.fe-maximize:before {
    content: $icon-fe-maximize;
}

$icon-fe-meh: '\f1dc';
.fe-meh:before {
    content: $icon-fe-meh;
}

$icon-fe-menu: '\f1dd';
.fe-menu:before {
    content: $icon-fe-menu;
}

$icon-fe-message-circle: '\f1de';
.fe-message-circle:before {
    content: $icon-fe-message-circle;
}

$icon-fe-message-square: '\f1df';
.fe-message-square:before {
    content: $icon-fe-message-square;
}

$icon-fe-mic-off: '\f1e0';
.fe-mic-off:before {
    content: $icon-fe-mic-off;
}

$icon-fe-mic: '\f1e1';
.fe-mic:before {
    content: $icon-fe-mic;
}

$icon-fe-minimize-2: '\f1e2';
.fe-minimize-2:before {
    content: $icon-fe-minimize-2;
}

$icon-fe-minimize: '\f1e3';
.fe-minimize:before {
    content: $icon-fe-minimize;
}

$icon-fe-minus-circle: '\f1e4';
.fe-minus-circle:before {
    content: $icon-fe-minus-circle;
}

$icon-fe-minus-square: '\f1e5';
.fe-minus-square:before {
    content: $icon-fe-minus-square;
}

$icon-fe-minus: '\f1e6';
.fe-minus:before {
    content: $icon-fe-minus;
}

$icon-fe-monitor: '\f1e7';
.fe-monitor:before {
    content: $icon-fe-monitor;
}

$icon-fe-moon: '\f1e8';
.fe-moon:before {
    content: $icon-fe-moon;
}

$icon-fe-more-horizontal: '\f1e9';
.fe-more-horizontal:before {
    content: $icon-fe-more-horizontal;
}

$icon-fe-more-vertical: '\f1ea';
.fe-more-vertical:before {
    content: $icon-fe-more-vertical;
}

$icon-fe-mouse-pointer: '\f1eb';
.fe-mouse-pointer:before {
    content: $icon-fe-mouse-pointer;
}

$icon-fe-move: '\f1ec';
.fe-move:before {
    content: $icon-fe-move;
}

$icon-fe-music: '\f1ed';
.fe-music:before {
    content: $icon-fe-music;
}

$icon-fe-navigation-2: '\f1ee';
.fe-navigation-2:before {
    content: $icon-fe-navigation-2;
}

$icon-fe-navigation: '\f1ef';
.fe-navigation:before {
    content: $icon-fe-navigation;
}

$icon-fe-octagon: '\f1f0';
.fe-octagon:before {
    content: $icon-fe-octagon;
}

$icon-fe-package: '\f1f1';
.fe-package:before {
    content: $icon-fe-package;
}

$icon-fe-paperclip: '\f1f2';
.fe-paperclip:before {
    content: $icon-fe-paperclip;
}

$icon-fe-pause-circle: '\f1f3';
.fe-pause-circle:before {
    content: $icon-fe-pause-circle;
}

$icon-fe-pause: '\f1f4';
.fe-pause:before {
    content: $icon-fe-pause;
}

$icon-fe-pen-tool: '\f1f5';
.fe-pen-tool:before {
    content: $icon-fe-pen-tool;
}

$icon-fe-percent: '\f1f6';
.fe-percent:before {
    content: $icon-fe-percent;
}

$icon-fe-phone-call: '\f1f7';
.fe-phone-call:before {
    content: $icon-fe-phone-call;
}

$icon-fe-phone-forwarded: '\f1f8';
.fe-phone-forwarded:before {
    content: $icon-fe-phone-forwarded;
}

$icon-fe-phone-incoming: '\f1f9';
.fe-phone-incoming:before {
    content: $icon-fe-phone-incoming;
}

$icon-fe-phone-missed: '\f1fa';
.fe-phone-missed:before {
    content: $icon-fe-phone-missed;
}

$icon-fe-phone-off: '\f1fb';
.fe-phone-off:before {
    content: $icon-fe-phone-off;
}

$icon-fe-phone-outgoing: '\f1fc';
.fe-phone-outgoing:before {
    content: $icon-fe-phone-outgoing;
}

$icon-fe-phone: '\f1fd';
.fe-phone:before {
    content: $icon-fe-phone;
}

$icon-fe-pie-chart: '\f1fe';
.fe-pie-chart:before {
    content: $icon-fe-pie-chart;
}

$icon-fe-play-circle: '\f1ff';
.fe-play-circle:before {
    content: $icon-fe-play-circle;
}

$icon-fe-play: '\f200';
.fe-play:before {
    content: $icon-fe-play;
}

$icon-fe-plus-circle: '\f201';
.fe-plus-circle:before {
    content: $icon-fe-plus-circle;
}

$icon-fe-plus-square: '\f202';
.fe-plus-square:before {
    content: $icon-fe-plus-square;
}

$icon-fe-plus: '\f203';
.fe-plus:before {
    content: $icon-fe-plus;
}

$icon-fe-pocket: '\f204';
.fe-pocket:before {
    content: $icon-fe-pocket;
}

$icon-fe-power: '\f205';
.fe-power:before {
    content: $icon-fe-power;
}

$icon-fe-printer: '\f206';
.fe-printer:before {
    content: $icon-fe-printer;
}

$icon-fe-radio: '\f207';
.fe-radio:before {
    content: $icon-fe-radio;
}

$icon-fe-refresh-ccw: '\f208';
.fe-refresh-ccw:before {
    content: $icon-fe-refresh-ccw;
}

$icon-fe-refresh-cw: '\f209';
.fe-refresh-cw:before {
    content: $icon-fe-refresh-cw;
}

$icon-fe-repeat: '\f20a';
.fe-repeat:before {
    content: $icon-fe-repeat;
}

$icon-fe-rewind: '\f20b';
.fe-rewind:before {
    content: $icon-fe-rewind;
}

$icon-fe-rotate-ccw: '\f20c';
.fe-rotate-ccw:before {
    content: $icon-fe-rotate-ccw;
}

$icon-fe-rotate-cw: '\f20d';
.fe-rotate-cw:before {
    content: $icon-fe-rotate-cw;
}

$icon-fe-rss: '\f20e';
.fe-rss:before {
    content: $icon-fe-rss;
}

$icon-fe-save: '\f20f';
.fe-save:before {
    content: $icon-fe-save;
}

$icon-fe-scissors: '\f210';
.fe-scissors:before {
    content: $icon-fe-scissors;
}

$icon-fe-search: '\f211';
.fe-search:before {
    content: $icon-fe-search;
}

$icon-fe-send: '\f212';
.fe-send:before {
    content: $icon-fe-send;
}

$icon-fe-server: '\f213';
.fe-server:before {
    content: $icon-fe-server;
}

$icon-fe-settings: '\f214';
.fe-settings:before {
    content: $icon-fe-settings;
}

$icon-fe-share-2: '\f215';
.fe-share-2:before {
    content: $icon-fe-share-2;
}

$icon-fe-share: '\f216';
.fe-share:before {
    content: $icon-fe-share;
}

$icon-fe-shield-off: '\f217';
.fe-shield-off:before {
    content: $icon-fe-shield-off;
}

$icon-fe-shield: '\f218';
.fe-shield:before {
    content: $icon-fe-shield;
}

$icon-fe-shopping-bag: '\f219';
.fe-shopping-bag:before {
    content: $icon-fe-shopping-bag;
}

$icon-fe-shopping-cart: '\f21a';
.fe-shopping-cart:before {
    content: $icon-fe-shopping-cart;
}

$icon-fe-shuffle: '\f21b';
.fe-shuffle:before {
    content: $icon-fe-shuffle;
}

$icon-fe-sidebar: '\f21c';
.fe-sidebar:before {
    content: $icon-fe-sidebar;
}

$icon-fe-skip-back: '\f21d';
.fe-skip-back:before {
    content: $icon-fe-skip-back;
}

$icon-fe-skip-forward: '\f21e';
.fe-skip-forward:before {
    content: $icon-fe-skip-forward;
}

$icon-fe-slack: '\f21f';
.fe-slack:before {
    content: $icon-fe-slack;
}

$icon-fe-slash: '\f220';
.fe-slash:before {
    content: $icon-fe-slash;
}

$icon-fe-sliders: '\f221';
.fe-sliders:before {
    content: $icon-fe-sliders;
}

$icon-fe-smartphone: '\f222';
.fe-smartphone:before {
    content: $icon-fe-smartphone;
}

$icon-fe-smile: '\f223';
.fe-smile:before {
    content: $icon-fe-smile;
}

$icon-fe-speaker: '\f224';
.fe-speaker:before {
    content: $icon-fe-speaker;
}

$icon-fe-square: '\f225';
.fe-square:before {
    content: $icon-fe-square;
}

$icon-fe-star: '\f226';
.fe-star:before {
    content: $icon-fe-star;
}

$icon-fe-stop-circle: '\f227';
.fe-stop-circle:before {
    content: $icon-fe-stop-circle;
}

$icon-fe-sun: '\f228';
.fe-sun:before {
    content: $icon-fe-sun;
}

$icon-fe-sunrise: '\f229';
.fe-sunrise:before {
    content: $icon-fe-sunrise;
}

$icon-fe-sunset: '\f22a';
.fe-sunset:before {
    content: $icon-fe-sunset;
}

$icon-fe-tablet: '\f22b';
.fe-tablet:before {
    content: $icon-fe-tablet;
}

$icon-fe-tag: '\f22c';
.fe-tag:before {
    content: $icon-fe-tag;
}

$icon-fe-target: '\f22d';
.fe-target:before {
    content: $icon-fe-target;
}

$icon-fe-terminal: '\f22e';
.fe-terminal:before {
    content: $icon-fe-terminal;
}

$icon-fe-thermometer: '\f22f';
.fe-thermometer:before {
    content: $icon-fe-thermometer;
}

$icon-fe-thumbs-down: '\f230';
.fe-thumbs-down:before {
    content: $icon-fe-thumbs-down;
}

$icon-fe-thumbs-up: '\f231';
.fe-thumbs-up:before {
    content: $icon-fe-thumbs-up;
}

$icon-fe-toggle-left: '\f232';
.fe-toggle-left:before {
    content: $icon-fe-toggle-left;
}

$icon-fe-toggle-right: '\f233';
.fe-toggle-right:before {
    content: $icon-fe-toggle-right;
}

$icon-fe-trash-2: '\f234';
.fe-trash-2:before {
    content: $icon-fe-trash-2;
}

$icon-fe-trash: '\f235';
.fe-trash:before {
    content: $icon-fe-trash;
}

$icon-fe-trello: '\f236';
.fe-trello:before {
    content: $icon-fe-trello;
}

$icon-fe-trending-down: '\f237';
.fe-trending-down:before {
    content: $icon-fe-trending-down;
}

$icon-fe-trending-up: '\f238';
.fe-trending-up:before {
    content: $icon-fe-trending-up;
}

$icon-fe-triangle: '\f239';
.fe-triangle:before {
    content: $icon-fe-triangle;
}

$icon-fe-truck: '\f23a';
.fe-truck:before {
    content: $icon-fe-truck;
}

$icon-fe-tv: '\f23b';
.fe-tv:before {
    content: $icon-fe-tv;
}

$icon-fe-twitter: '\f23c';
.fe-twitter:before {
    content: $icon-fe-twitter;
}

$icon-fe-type: '\f23d';
.fe-type:before {
    content: $icon-fe-type;
}

$icon-fe-umbrella: '\f23e';
.fe-umbrella:before {
    content: $icon-fe-umbrella;
}

$icon-fe-underline: '\f23f';
.fe-underline:before {
    content: $icon-fe-underline;
}

$icon-fe-unlock: '\f240';
.fe-unlock:before {
    content: $icon-fe-unlock;
}

$icon-fe-upload-cloud: '\f241';
.fe-upload-cloud:before {
    content: $icon-fe-upload-cloud;
}

$icon-fe-upload: '\f242';
.fe-upload:before {
    content: $icon-fe-upload;
}

$icon-fe-user-check: '\f243';
.fe-user-check:before {
    content: $icon-fe-user-check;
}

$icon-fe-user-minus: '\f244';
.fe-user-minus:before {
    content: $icon-fe-user-minus;
}

$icon-fe-user-plus: '\f245';
.fe-user-plus:before {
    content: $icon-fe-user-plus;
}

$icon-fe-user-x: '\f246';
.fe-user-x:before {
    content: $icon-fe-user-x;
}

$icon-fe-user: '\f247';
.fe-user:before {
    content: $icon-fe-user;
}

$icon-fe-users: '\f248';
.fe-users:before {
    content: $icon-fe-users;
}

$icon-fe-video-off: '\f249';
.fe-video-off:before {
    content: $icon-fe-video-off;
}

$icon-fe-video: '\f24a';
.fe-video:before {
    content: $icon-fe-video;
}

$icon-fe-voicemail: '\f24b';
.fe-voicemail:before {
    content: $icon-fe-voicemail;
}

$icon-fe-volume-1: '\f24c';
.fe-volume-1:before {
    content: $icon-fe-volume-1;
}

$icon-fe-volume-2: '\f24d';
.fe-volume-2:before {
    content: $icon-fe-volume-2;
}

$icon-fe-volume-x: '\f24e';
.fe-volume-x:before {
    content: $icon-fe-volume-x;
}

$icon-fe-volume: '\f24f';
.fe-volume:before {
    content: $icon-fe-volume;
}

$icon-fe-watch: '\f250';
.fe-watch:before {
    content: $icon-fe-watch;
}

$icon-fe-wifi-off: '\f251';
.fe-wifi-off:before {
    content: $icon-fe-wifi-off;
}

$icon-fe-wifi: '\f252';
.fe-wifi:before {
    content: $icon-fe-wifi;
}

$icon-fe-wind: '\f253';
.fe-wind:before {
    content: $icon-fe-wind;
}

$icon-fe-x-circle: '\f254';
.fe-x-circle:before {
    content: $icon-fe-x-circle;
}

$icon-fe-x-octagon: '\f255';
.fe-x-octagon:before {
    content: $icon-fe-x-octagon;
}

$icon-fe-x-square: '\f256';
.fe-x-square:before {
    content: $icon-fe-x-square;
}

$icon-fe-x: '\f257';
.fe-x:before {
    content: $icon-fe-x;
}

$icon-fe-youtube: '\f258';
.fe-youtube:before {
    content: $icon-fe-youtube;
}

$icon-fe-zap-off: '\f259';
.fe-zap-off:before {
    content: $icon-fe-zap-off;
}

$icon-fe-zap: '\f25a';
.fe-zap:before {
    content: $icon-fe-zap;
}

$icon-fe-zoom-in: '\f25b';
.fe-zoom-in:before {
    content: $icon-fe-zoom-in;
}

$icon-fe-zoom-out: '\f25c';
.fe-zoom-out:before {
    content: $icon-fe-zoom-out;
}


// stylelint-disable property-no-vendor-prefix
html {
  height: 100%;
  font-size: 16px;

  @include media-breakpoint-down(md) {
    font-size: 15px;
  }
}

body {
  height: 100%;
  overflow-y: scroll;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga" 0;
}

body *::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  transition: .3s background;
}

body *::-webkit-scrollbar-thumb {
  background: $gray-400;
}

body *:hover::-webkit-scrollbar-thumb {
  background: $gray-500;
}

/**
Remove the cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
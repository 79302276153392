.custom-switch,
.custom-radio,
.custom-checkbox {
  margin-bottom: 0;
  user-select: none;

  &:not(:first-child) {
    margin-top: .25rem;
  }
}

/**
Icon input
 */
.input-icon {
  position: relative;

  .form-control:not(:last-child) {
    padding-right: 2.5rem;
  }

  .form-control:not(:first-child) {
    padding-left: 2.5rem;
  }
}

.input-icon-addon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.5rem;
  color: $text-muted;
  pointer-events: none;

  svg {
    width: 1rem;
    height: 1rem;
  }

  &:last-child {
    right: 0;
    left: auto;
  }
}


/*
Color Input
 */
.colorinput {
  position: relative;
  margin: 0;
  cursor: pointer;
}

.colorinput-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.colorinput-color {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  color: #fff;
  border: 1px solid $border-color;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);

  &::before {
    position: absolute;
    top: .25rem;
    left: .25rem;
    width: 1.25rem;
    height: 1.25rem;
    content: "";
    background: $form-switch-checked-bg-image no-repeat center center/50% 50%;
    opacity: 0;
    transition: .3s opacity;

    .colorinput-input:checked ~ & {
      opacity: 1;
    }
  }

  .colorinput-input:focus ~ & {
    border-color: $primary;
    box-shadow: $input-btn-focus-box-shadow;
  }
}

/*
Selectgroup
 */
.selectgroup {
  display: inline-flex;
}

.selectgroup-vertical {
  flex-direction: column;
}

.selectgroup-item {
  position: relative;
  flex-grow: 1;
}

.selectgroup:not(.selectgroup-vertical) > .selectgroup-item {
  & + .selectgroup-item {
    margin-left: -1px;
  }

  &:not(:first-child) .selectgroup-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:not(:last-child) .selectgroup-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.selectgroup-vertical > .selectgroup-item {
  &:not(:last-child) {
    margin-bottom: 0;
  }

  & + .selectgroup-item {
    margin-top: -1px;
    margin-left: 0;
  }

  &:not(:first-child) .selectgroup-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &:not(:last-child) .selectgroup-button {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.selectgroup-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}

.selectgroup-button {
  position: relative;
  display: block;
  min-width: $input-height;
  padding: $input-btn-padding-y 1rem;
  font-size: $font-size-base;
  line-height: $input-line-height;
  color: $text-muted;
  text-align: center;
  cursor: pointer;
  user-select: none;
  border: 1px solid $input-border-color;
  border-radius: 3px;
  background: rgba(#fff, .064);
}

.selectgroup-input:checked + .selectgroup-button {
  z-index: 1;
  color: $primary;
  background: rgba($primary, .08);
  border-color: $primary;
}

.selectgroup-input:focus + .selectgroup-button {
  // z-index: 2;
  // color: $primary;
  // border-color: $primary;
  // box-shadow: $input-btn-focus-box-shadow;
}

.selectgroup-pills {
  flex-wrap: wrap;
  align-items: flex-start;

  .selectgroup-item {
    flex-grow: 0;
  }

  .selectgroup-button {
    border-radius: 50px;
  }
}


/**
Image check
 */
.imagecheck {
  position: relative;
  margin: 0;
  cursor: pointer;
}

.imagecheck-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.imagecheck-figure {
  position: relative;
  margin: 0;
  border: 1px solid $border-color;
  border-radius: 3px;

  .imagecheck-input:focus ~ & {
    border-color: $primary;
    box-shadow: $input-btn-focus-box-shadow;
  }

  .imagecheck-input:checked ~ & {
    border-color: $border-color;
  }

  &::before {
    position: absolute;
    top: .25rem;
    left: .25rem;
    z-index: 1;
    display: block;
    width: 1rem;
    height: 1rem;
    color: #fff;
    pointer-events: none;
    content: "";
    user-select: none;
    background: $primary $form-switch-checked-bg-image no-repeat center center/50% 50%;
    border-radius: 3px;
    opacity: 0;
    transition: .3s opacity;

    .imagecheck-input:checked ~ & {
      opacity: 1;
    }
  }
}

.imagecheck-image {
  max-width: 100%;
  opacity: .64;
  transition: .3s opacity;

  &:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  &:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  .imagecheck:hover &,
  .imagecheck-input:focus ~ .imagecheck-figure &,
  .imagecheck-input:checked ~ .imagecheck-figure & {
    opacity: 1;
  }
}

.imagecheck-caption {
  padding: .25rem;
  font-size: $font-size-sm;
  color: $text-muted;
  text-align: center;
  transition: .3s color;

  .imagecheck:hover &,
  .imagecheck-input:focus ~ .imagecheck-figure &,
  .imagecheck-input:checked ~ .imagecheck-figure & {
    color: $body-color;
  }
}

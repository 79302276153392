.calendar {
  display: block;
  font-size: $font-size-sm;
  border: 1px solid $border-color;
  border-radius: $border-radius;
}

.calendar-nav {
  display: flex;
  align-items: center;
}

.calendar-title {
  flex: 1;
  text-align: center;
}

.calendar-body,
.calendar-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: .5rem 0;
}

.calendar-header {
  color: $text-muted-light;
}

.calendar-date {
  flex: 0 0 (100% / 7);
  max-width: (100% / 7);
  padding: .2rem;
  text-align: center;
  border: 0;

  &.prev-month,
  &.next-month {
    opacity: .25;
  }

  .date-item {
    position: relative;
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
    line-height: 1.4rem;
    color: #66758c;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    background: 0 0;
    border: 1px solid transparent;
    border-radius: 50%;
    outline: 0;
    transition: background .3s, border .3s, box-shadow .32s, color .3s;

    &:hover {
      color: $primary;
      text-decoration: none;
      background: #fefeff;
      border-color: $border-color;
    }
  }

  .date-today {
    color: $primary;
    border-color: $border-color;
  }
}

.calendar-range {
  position: relative;

  &::before {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: 1.4rem;
    content: "";
    background: rgba($primary, .1);
    transform: translateY(-50%);
  }

  &.range-start,
  &.range-end {
    .date-item {
      color: #fff;
      background: $primary;
      border-color: $primary;
    }
  }

  &.range-start::before {
    left: 50%;
  }

  &.range-end::before {
    right: 50%;
  }
}
